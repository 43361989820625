export const countries = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Asia",
  },
  {
    id: 2,
    name: "Europe",
  },
  {
    id: 3,
    name: "North America",
  },
  {
    id: 4,
    name: "Oceania",
  },
  {
    id: 5,
    name: "SwimMastery Advance Coach",
  },
  {
    id: 6,
    name: "SwimMastery Trainer",
  },
  {
    id: 7,
    name: "SwimMastery High Performance Coach",
  },
  {
    id: 8,
    name: "SwimMastery Coach",
  },
  {
    id: 9,
    name: "Swim School Instructor",
  },
  {
    id: 10,
    name: "Training Director",
  },
];
